import * as React from "react";

import Layout from "../layouts/Layout";

export default function NotFoundPage() {
    return (
        <Layout title="Not Found | FaintLines">
            <h1>{"Page Not Found"}</h1>
        </Layout>
    );
}
